<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    },
  },
  mounted() {
    this.$watch("$route", this.disableRTL, { immediate: true });
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);
  },
};
</script>

<style lang="scss">
h5 {
  font-size: 16px !important;
}

.btn {
  font-size: 16px !important;
}
input {
  border-radius: 4px !important;
  -webkit-box-shadow: rgba(32, 32, 32, 0.158) 0px 0 10px !important;
  -moz-box-shadow: rgba(32, 32, 32, 0.158) 0px 0 10px !important;
  box-shadow: rgba(32, 32, 32, 0.158) 0px 0 10px !important;
  border-color: transparent !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  font-size: 16px !important;
}
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
}
input::placeholder {
  color: rgb(230, 230, 230) !important;
  opacity: 1 !important;
}

input:-ms-input-placeholder {
  color: rgb(230, 230, 230) !important;
  opacity: 1 !important;
}

input::-ms-input-placeholder {
  color: rgb(230, 230, 230) !important;
  opacity: 1 !important;
}

.circle-icon {
  height: 50px;
  width: 50px;

  border-radius: 50%;

  position: absolute;

  left: 50%;

  top: -25px;
  z-index: 100;
}
.center {
  position: absolute;

  left: 50%;

  top: 50%;

  transform: translate(-50%, -50%);
}

.card-hover {
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  //padding: 20px;
  overflow: hidden;
  background: #ffffffd7;
  border-radius: 10px;
  box-shadow: -2px 2px 0px 0px #06060668,
    -10px 10px 40px 0px rgba(82, 82, 82, 0.3);

  z-index: 2;
  transition: all 1s;
  &:hover {
    //transform: translate(0px, -20px) rotateX(10deg) rotateZ(-20deg);
    box-shadow: -2px 1px 0px 0px #2e2e2e68,
      -40px 40px 40px 0 rgba(78, 78, 78, 0.2);
    cursor: pointer;
  }
}

.card-container {
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .card {
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    height: 135px;
    padding: 20px;
    overflow: hidden;
    background: linear-gradient( 180deg , rgba(166,105,22,1)  35%, rgba(234,179,94,1)100%) !important;
    border-radius: 10px;
    box-shadow: -2px 2px 0px 0px #00000021,
      -10px 10px 40px 0px rgba(141, 141, 141, 0.3);

    z-index: 2;
    transition: all 1s;
    // &:hover {
    //   //transform: translate(0px, -20px) rotateX(10deg) rotateZ(-20deg);
    //   box-shadow: -2px 1px 0px 0px #d3d3d3,
    //     -40px 40px 40px 0 rgba(78, 78, 78, 0.2);
    //   cursor: pointer;
    // }
    h1 {
      color: rgb(255, 255, 255);
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 4px;
      &:nth-of-type(2) {
        font-size: 16px;
        text-align: center;
      }
    }
    h6 {
      color: rgb(255, 255, 255);
      font-size: 30px;
      text-transform: uppercase;
      
    
      &:nth-of-type(2) {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }
    h3 {
      color: rgb(255, 255, 255);
      font-size: 12px;
      text-transform: uppercase;
       margin-bottom: 14px;
      &:nth-of-type(2) {
        font-size: 14px;
        font-weight: 400;
      }
    }
    i {
      margin-right: 5px;
      color: crimson;
    }
    .circle {
      margin: auto;
      top: 10%;
      bottom: 0;
      right: -50%;
      width: 300px;
      height: 300px;
      border-radius: 50%;
      background: #202020;
      z-index: -2;
      &:nth-of-type(2) {
        width: 100px;
        height: 100px;
        top: 90%;
        right: -20%;
        z-index: -1;
        background: crimson;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .card-container {
.card{
   height: 160px;
}}}
</style>
