<template>
  <!-- <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
  > -->

  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ '': !showMenu }"
    style="background-color: #171616"
  >
    <div class="container-fluid">
      <a
        class="navbar-brand m-0"
        style="color: rgba(234, 179, 94, 1)"
        href="/"
        >{{ routeName }}</a
      >
      <img
        class="navbar-toggler"
        v-bind:src="'/img/logo.png'"
        style="width: 78px"
      />
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul
            :class="[
              'navbar-nav',
              $rtl.isRTL ? 'mr-auto ' : 'ml-auto',
              'navbar-nav',
              'col-lg-8',
            ]"
          >
            <a
              :class="['btn btn-sm btn-block', !isTrial ? '' : 'btn-info']"
              style="
                background-image: linear-gradient(
                  180deg,
                  rgba(166, 105, 22, 1) 35%,
                  rgba(234, 179, 94, 1) 100%
                ) !important;
              "
              @click="trial()"
            >
              {{ !isTrial ? `ทดลองเล่น` : `กลับ User หลัก` }}
            </a>
            <a
              :class="['btn btn-sm btn-block']"
              style="
                background-image: linear-gradient(
                  180deg,
                  rgba(166, 105, 22, 1) 35%,
                  rgba(234, 179, 94, 1) 100%
                ) !important;
              "
              :href="lineHref"
              target="_blank"
            >
              <img
                v-bind:src="'/img/line.png'"
                class="logo-line"
                style="background: #00b900"
              />
              ติดต่อสอบถาม @autobot999
            </a>

            <a
              :class="[
                'btn btn-sm btn-block btn-login btn-secondary ',
                showMenu ? '' : ' w-50',
              ]"
              style="
                background-image: linear-gradient(
                  180deg,
                  rgba(166, 105, 22, 1) 35%,
                  rgba(234, 179, 94, 1) 100%
                ) !important;
              "
            >
              {{ displayName }}
            </a>
            <a
              :class="['btn btn-sm btn-danger', showMenu ? '' : ' w-50']"
              style="background-image:linear-gradient( 180deg , rgb(166, 22, 22)  35%, rgb(234, 94, 94)100%) !important; !important; "
              href="/logout"
              >Logout</a
            >
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Modal from "@/components/Modal";

export default {
  mounted() {
    this.$store.dispatch("checkAuth").then(() => {
      // console.log("dd");
    });
    // console.log("is_trial", this.isTrial);
    // console.log(
    //   `${JSON.parse(localStorage.getItem("user_main")).user_id}_user_trial`
    // );
  },
  components: {
    CollapseTransition,
    Modal,
  },
  computed: {
    isTrial() {
      return localStorage.getItem("trial_active") != "false" ? true : false;
    },
    tempTrial() {
      return localStorage.getItem("user_trial") != null
        ? localStorage.getItem(
            `${
              JSON.parse(localStorage.getItem("user_main")).user_id
            }_user_trial`
          )
        : null;
    },
    lineHref: function () {
      return "https://bit.ly/3krGOe9";
    },
    displayName() {
      return this.$store.getters.getDisplayName;
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    kill() {
      localStorage.removeItem("trial_active");
      localStorage.removeItem("user_main");
      localStorage.removeItem("user_trial");
      localStorage.removeItem("459_user_trial");
      // localStorage.removeItem(
      //   `${JSON.parse(localStorage.getItem("user_main")).user_id}_user_trial`
      // );
      console.log("kill");
    },
    trial() {
      if (this.isTrial) {
        this.$store
          .dispatch(
            "setToken",
            JSON.parse(localStorage.getItem("user_main")).user_id
          )
          .then(() => {
            this.$store.dispatch(
              "updateUserInfo",
              JSON.parse(localStorage.getItem("user_main"))
            );
            localStorage.setItem("trial_active", false);

            window.location.reload();
          });
      } else {
        if (this.tempTrial != null) {
          // console.log(JSON.parse(localStorage.getItem("user_trial")));
          this.$store
            .dispatch(
              "setToken",
              JSON.parse(localStorage.getItem("user_trial")).user_id
            )
            .then(() => {
              this.$store.dispatch(
                "updateUserInfo",
                JSON.parse(localStorage.getItem("user_trial"))
              );
              localStorage.setItem("trial_active", true);

              window.location.reload();
            });
        } else {
          const genUser = Math.random().toString(36).substring(2, 8);
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/create_mock_user`, {
              username: genUser,
              password: "trial_",
              wallet: 20000,
            })
            .then(({ data }) => {
              if (data.success) {
                localStorage.setItem("user_trial", JSON.stringify(data.data));
                localStorage.setItem(
                  `${
                    JSON.parse(localStorage.getItem("user_main")).user_id
                  }_user_trial`,
                  true
                );
                this.$store.dispatch("setToken", data.data.user_id).then(() => {
                  this.$store.dispatch("updateUserInfo", data.data);
                  localStorage.setItem("trial_active", true);

                  window.location.reload();
                });
              } else {
                this.errorMsg = data.message;
              }
            })
            .catch((err) => {
              this.errorMsg = err.response.data.message;
              return;
            });
        }

        // console.log(localStorage.getItem("trial_active"));
      }
    },
  },
};
</script>
<style lang="scss">
</style>
